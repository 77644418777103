/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { Budget } from "@models/Budget";
import { updateOrderBudget } from "@redux/slices/orders/orderHistorySlice";
import { ResourceAutocomplete } from "@utils/forms";

const EditOrderBudgetModal = ({ order, onClose }) => {
  const dispatch = useDispatch();
  const { budgetLocation } = useSelector(
    (state: any) => state.currentUser.organization
  );

  // The only time where order-variants would have different budget-ids is
  // if budget-locations === "group", which we don't the possiblity of changing for now
  const savedBudgetId = order.variants[0].budgetId;
  const [budget, setBudget] = useState<Budget | null>(null);
  const [updateState, setUpdateState] = useState<string | null>(null);

  const budgetParams = {
    filter: {
      isActive: true,
      ...(budgetLocation === "user" && { userIds: [order.userId] }),
      ...(budgetLocation === "territory" && {
        territoryIds: [order.territoryId],
      }),
    },
  };

  const handleSave = () => {
    if (!budget) return;
    setUpdateState("loading");
    dispatch(
      updateOrderBudget(order.id, budget.id, () => setUpdateState("success"))
    );
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle tw="flex items-center justify-between">
        Edit Order Budget
        <IconButton edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="space-y-3 overflow-visible">
        <h3 tw="font-medium">Current budget:</h3>
        {order.budget}
        <br />
        <ResourceAutocomplete
          label="New Budget"
          resource="budgets"
          requestParams={budgetParams}
          value={budget}
          onChange={(e, val) => setBudget(val)}
        />
        <div tw="flex gap-3 items-center">
          <StyledButton
            disabled={!budget || budget.id === savedBudgetId}
            cta
            onClick={handleSave}
            loading={updateState === "loading"}
          >
            Save
          </StyledButton>
          {updateState === "success" && <span>Success!</span>}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditOrderBudgetModal;
