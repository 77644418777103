import { Order } from "@models";

const calculateOrderTotal = (order: Order, organization: any) => {
  const {
    includeShippingInBudgets,
    includeTaxesInBudget,
    includeShippingInStripeCharge,
    includeTaxesInStripeCharge,
  } = organization;

  const shipping =
    order.totalActualShippingCost || order.totalEstimatedShippingCost;
  const taxes = order.totalEstimatedTax;

  let totalCost = order.totalPrice;

  if (order.checkoutType === "budget") {
    if (includeShippingInBudgets) totalCost += shipping;
    if (includeTaxesInBudget) totalCost += taxes;
  } else if (order.checkoutType === "stripe") {
    if (includeShippingInStripeCharge) totalCost += shipping;
    if (includeTaxesInStripeCharge) totalCost += taxes;
  } else {
    totalCost += shipping + taxes;
  }
  return totalCost;
};

export default calculateOrderTotal;
