import {
  axiosGet,
  axiosGetWithNext,
  axiosPatch,
  axiosPost,
} from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";
import { kebabCaseKeys } from "@utility/utilityFunctions";

import { buildFilters, buildSort } from "../../../api/apiFunctions";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  updateStepperValue,
} from "../globalStateSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import {
  getReportsSuccess,
  setIsLoading as setReportLoading,
} from "../reports/reportSlice";
import {
  buildAddressChangeRequest,
  buildOrderCancelRequest,
  buildOrderVariantPatch,
} from "./helpers";
import { mapOrderVariants, mapOrders } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  currentOrder: null,
  hasUpdated: false,
  newOrderId: null,
  error: null,
};

const orderHistorySlice = createSlice({
  name: "orderHistory",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getOrderSuccess(state, action) {
      const { order } = action.payload;
      state.currentOrder = order;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateVariantSuccess(state, action) {
      const { variant, type } = action.payload;
      let newVariants = state.currentOrder.variants.map((v) => {
        if (v.id === variant.id) {
          return variant;
        } else return v;
      });
      state.currentOrder = { ...state.currentOrder, variants: newVariants };
      state.isUpdateLoading = false;
      if (type === "qty") {
        state.hasUpdated = true;
      }
      state.error = null;
    },
    updateVariantAddressSuccess(state, action) {
      const { originalId, newId } = action.payload;
      state.hasUpdated = true;
      state.newOrderId = newId;
      let currentVariants = state.currentOrder.variants;
      let newVariants = currentVariants.filter((v) => v.id !== originalId);
      state.currentOrder = { ...state.currentOrder, variants: newVariants };
      state.isUpdateLoading = false;
      state.error = null;
    },
    resetUpdate(state) {
      state.hasUpdated = false;
      state.newOrderId = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  getOrderSuccess,
  updateVariantAddressSuccess,
  updateVariantSuccess,
  resetUpdate,
  setFailure,
} = orderHistorySlice.actions;

export default orderHistorySlice.reducer;

export const fetchOrder = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/orders/${id}`);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrders([response.data])[0];
    dispatch(getOrderSuccess({ order: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
  }
};

export const cancelOrder = (id, note) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildOrderCancelRequest(id, note);
    const response = await axiosPost(`/api/orders/${id}/cancel`, postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrders([response.data])[0];
    mappedData.variants = mappedData.variants.map((v) => ({
      ...v,
      status: "canceled",
    }));
    dispatch(getOrderSuccess({ order: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(patchFailure());
  }
};

export const cancelOrderVariant = (id, note) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildOrderCancelRequest(id, note);
    const response = await axiosPost(
      `/api/order-variants/${id}/cancel`,
      postData
    );
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrderVariants([response.data])[0];
    dispatch(updateVariantSuccess({ variant: mappedData, type: "cancel" }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(patchFailure());
  }
};

export const updateVariantQty = (id, qty) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patch = buildOrderVariantPatch(id, "qty", qty);
    const response = await axiosPatch(`/api/order-variants/${id}`, patch);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrderVariants([response.data])[0];
    dispatch(updateVariantSuccess({ variant: mappedData, type: "qty" }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(patchFailure());
  }
};

export const updateOrderAddress = (id, addressId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildAddressChangeRequest(id, addressId);
    const response = await axiosPost(
      `/api/orders/${id}/update-address`,
      postData
    );
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrders([response.data])[0];
    dispatch(getOrderSuccess({ order: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(patchFailure());
  }
};
export const updateOrderBudget =
  (id, budgetId, callback) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const postData = {
        id,
        "budget-id": +budgetId,
      };
      const response = await axiosPost(
        `/api/orders/${id}/update-budget`,
        postData
      );
      if (response.error) {
        throw response.error;
      }
      const mappedData = mapOrders([response.data])[0];
      dispatch(getOrderSuccess({ order: mappedData }));
      dispatch(patchSuccess());
      callback?.();
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({
          error: err.toString(),
          source: "Order History update budget",
        })
      );
      dispatch(patchFailure());
    }
  };

export const updateOrder = (id, attributes, callback) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = {
      data: {
        attributes: kebabCaseKeys(attributes),
      },
    };
    const response = await axiosPatch(`/api/orders/${id}`, postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrders([response.data])[0];
    dispatch(getOrderSuccess({ order: mappedData }));
    dispatch(patchSuccess());
    callback?.();
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({
        error: err.toString(),
        source: "Order History Update",
      })
    );
    dispatch(patchFailure());
  }
};

export const updateVariantAddress = (id, addressId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildAddressChangeRequest(id, addressId);
    const response = await axiosPost(
      `/api/order-variants/${id}/update-address`,
      postData
    );
    if (response.error) {
      throw response.error;
    }
    dispatch(
      updateVariantAddressSuccess({
        originalId: id,
        newId: response.data.order.id,
      })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(patchFailure());
  }
};

export const fetchHistoryReport =
  (filters, maybeCallback) => async (dispatch) => {
    try {
      dispatch(
        setIsStepper({
          stepBool: true,
          stepTitle: "Generating Order History Report",
        })
      );
      dispatch(setReportLoading());

      let data = [];
      let stepValue = 10;
      let nextLink = null;

      let queryBase =
        filters.groupOrderHistoryBy === "order"
          ? "/api/orders"
          : "/api/order-variants";
      let sortString = buildSort(filters);
      let queryString = buildFilters(filters, "", sortString, queryBase);

      const initialResponse = await axiosGetWithNext(queryString);
      if (initialResponse.error) {
        throw initialResponse.error;
      }
      const initialMap =
        filters.groupOrderHistoryBy === "order"
          ? mapOrders(initialResponse.data.data)
          : mapOrderVariants(initialResponse.data.data);

      data = data.concat(initialMap);
      dispatch(updateStepperValue({ value: stepValue }));
      nextLink = initialResponse.data.nextLink;

      if (nextLink) {
        const pageSize = initialResponse.data.data.length;
        let fetchCount =
          Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
        stepValue = parseFloat((90 / fetchCount).toFixed(2));

        for (let i = 0; i < fetchCount; i++) {
          let nextResponse = await axiosGetWithNext(nextLink);
          if (nextResponse.error) {
            throw nextResponse.error;
          }
          nextLink = nextResponse.data.nextLink;
          let mappedData =
            filters.groupOrderHistoryBy === "order"
              ? mapOrders(nextResponse.data.data)
              : mapOrderVariants(nextResponse.data.data);
          data = data.concat(mappedData);
          dispatch(updateStepperValue({ value: stepValue }));
        }
      } else {
        dispatch(updateStepperValue({ value: 90 }));
      }
      dispatch(resetStepperValue());
      dispatch(
        getReportsSuccess({ type: filters.reportType, reportData: data })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Order History" }));
      dispatch(resetStepperValue());
    } finally {
      maybeCallback?.();
    }
  };
