/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Help } from "@mui/icons-material";
import { Slide } from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";
// @ts-ignore
import logoUrl from "src/assets/logo-select-white-color.svg";

import { StyledButton } from "@components/StyledComponents";
import Loading from "@components/Utility/Loading";
import SessionExpiredModal from "@components/Utility/Modals/SessionExpiredModal";
import { setError } from "@redux/slices/errorSlice";
import DocTitle from "@utility/DocTitle";

import useUrlQuery from "../hooks/useUrlQuery";

const splashImages = {
  default: "/images/cara-thomson-nag4bBWfa88-unsplash.jpg",
  nokian:
    "https://res.cloudinary.com/brandhub/image/upload/v1640013705/prod/Brandhub/nokian_splash_qecxh4.jpg",
  nokiantyres:
    "https://res.cloudinary.com/brandhub/image/upload/v1640013705/prod/Brandhub/nokian_splash_qecxh4.jpg",
  benandjerrys:
    "https://res.cloudinary.com/brandhub/image/upload/v1642021489/prod/Brandhub/ben_and_jerrys_splash_gtloct.jpg",
  saucony:
    "https://res.cloudinary.com/brandhub/image/upload/v1652721631/prod/Brandhub/saucony_splash.jpg",
  whistlepig:
    "https://res.cloudinary.com/brandhub/image/upload/v1653064483/prod/Brandhub/whistlepig-splash.jpg",
  keen: "https://res.cloudinary.com/brandhub/image/upload/v1654258827/prod/Brandhub/keen-splash.jpg",
  gore: "https://res.cloudinary.com/brandhub/image/upload/v1656445471/prod/Brandhub/gore-splash.jpg",
  montenegro:
    "https://res.cloudinary.com/brandhub/image/upload/v1665760071/prod/Brandhub/montenegro_splash.jpg",
  pizzahut:
    "https://res.cloudinary.com/brandhub/image/upload/v1673538364/prod/Brandhub/pizzahut-splash.png",
  boarshead:
    "https://res.cloudinary.com/brandhub/image/upload/v1681827525/prod/Brandhub/boarshead-splash.jpg",
  generalmills:
    "https://res.cloudinary.com/brandhub/image/upload/v1684334227/prod/Brandhub/general-mills-splash.jpg",
  titosvodka:
    "https://res.cloudinary.com/brandhub/image/upload/v1684334227/prod/Brandhub/titos-splash_ltnfv0.jpg",
};

const Landing = () => {
  const dispatch = useDispatch();
  const { loginWithPopup, isLoading } = useAuth0();
  const subDomain = window.location.host.split(".")[0];

  const navigate = useNavigate();
  const splashImageUrl = splashImages[subDomain] || splashImages.default;

  const [sessionExpiredOpen, setSessionExpiredOpen] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const params = Object.fromEntries(useUrlQuery());

  const handleClose = () => setSessionExpiredOpen(false);

  useEffect(() => {
    let id = setTimeout(() => setShowHelp(true), 10000);
    if (params.user_error) {
      navigate(window.location.pathname, { replace: true });
      dispatch(
        setError({
          error:
            "User not found, please contact your administrator and make sure you are added to the system",
          source: "User Login",
        })
      );
    }

    if (params.expired) {
      navigate(params.r ?? window.location.pathname, { replace: true });
      setSessionExpiredOpen(true);
    }
    return () => clearTimeout(id);
  }, [dispatch, navigate, params.expired, params.r, params.user_error]);

  return (
    <>
      <DocTitle title={"Landing"} />
      <div tw="h-screen flex flex-col">
        {sessionExpiredOpen && (
          <SessionExpiredModal
            open={sessionExpiredOpen}
            handleClose={handleClose}
          />
        )}
        {isLoading && <Loading partial={false} opacity={0.5} />}
        <div tw="flex-none bg-gray-800 z-10 relative">
          <div tw="container flex items-center justify-between mx-auto">
            <img
              src={logoUrl}
              alt="select brandhub logo"
              tw="py-4 w-auto h-20"
            />

            <StyledButton onClick={loginWithPopup}>LOG IN</StyledButton>
          </div>
        </div>
        <div tw="grow relative h-full overflow-hidden">
          <div
            css={[
              tw`absolute inset-0 w-full h-full scale-[1.01] bg-black bg-cover bg-center`,
              subDomain === "titosvodka" && tw`[background-position: 10% 80%]`,
            ]}
            style={{
              backgroundImage: `url(${splashImageUrl})`,
            }}
          />

          <div tw="container mx-auto absolute bottom-0 left-0 right-0 pb-4 text-right text-neutral-200">
            <Slide direction="up" in={showHelp}>
              <div tw="inline-block bg-black/60 p-4 px-6 mb-2 rounded-md">
                <div tw="flex gap-3 items-center">
                  <div tw="relative flex items-center justify-center">
                    <div tw="inset-0 rounded-full bg-white/50 animate-ping absolute" />
                    <Help />
                  </div>
                  <p>
                    Having trouble logging in? Please contact{" "}
                    <a
                      href="mailto:help@selectbrandhub.com"
                      tw="text-primary-100 underline"
                    >
                      help@selectbrandhub.com
                    </a>
                  </p>
                </div>
              </div>
            </Slide>
            <div tw="text-sm">
              &#169; <b>brandhub</b> {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
